import "%modules%/header/header";
import "%modules%/main-slider/main-slider";
import "%modules%/slider/slider";
import "%modules%/form/form";
import "%modules%/feedback/feedback";
import "%modules%/contacts/contacts";
import "%modules%/reviews/reviews";
import "%modules%/select2/select2";
import "%modules%/tab/tab";
import "%modules%/modal/modal";
import "%modules%/filter/filter";
import "%modules%/news/news";