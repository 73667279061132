$(function() {
        
    $('.js-reviews-slider').slick({
        slidesToShow: 1,
        arrows: false,
        asNavFor: '.js-reviews-thumbnail',
        vertical: false,
        autoplay: false,
        verticalSwiping: false,
        centerMode: false,
        useTransform: false,
    });

    $('.js-reviews-slider').on('init', function(event, slick){
        console.log("initialised")
        $('.js-reviews-slider').slick()
    });

    $('.js-reviews-thumbnail').slick({
        slidesToShow: 4,
        arrows: false,
        vertical: true,
        focusOnSelect: true,
        verticalSwiping: false,
        autoplay: false,
        centerMode: false,      
        asNavFor: '.js-reviews-slider',
        responsive: [
            {
                breakpoint: 768,
                settings: "unslick"
            },
        ],
    });

})