$(function() {

    let timerElement = $('.js-timer');

    function timer() {

        function getNormalisedDatetime(dString) { // yyyy-mm-dd hh:mm:ss
            let parts = dString.split(' ');
            let dParts = parts[0].split('-');
            let tParts = parts[1].split(':');
            return new Date(dParts[0], dParts[1] - 1, dParts[2], tParts[0], tParts[1], tParts[2]);
        }

        if ($('body').find(timerElement).length) {
            let date = timerElement.data('timer');
            // Установка времени для обратного отчета
            let dateNew = getNormalisedDatetime(date);

            timerElement.countdown(dateNew, function (event) {
                $(this).html(
                    event.strftime(
                        `<div class="countdown">
                            <div class="countdown__item">
                                <div class="countdown__block"> %D </div>
                                <div class="countdown__title">дней</div>
                            </div>
                            <div class="countdown__item">
                                <div class="countdown__block"> %H </div>
                                <div class="countdown__title">часов</div>
                            </div>
                            <div class="countdown__item">
                                <div class="countdown__block"> %M </div>
                                <div class="countdown__title">минут</div>
                            </div>
                            <div class="countdown__item">
                                <div class="countdown__block"> %S </div>
                                <div class="countdown__title">секунд</div>
                            </div>
                        </div>`
                    )
                );
            });
        }
    }

    timer();

})
