$(function() {

    let selects = document.querySelectorAll(".js-select2");

    selects.forEach((selectEl) => {
        var $select = $(selectEl);
        $select.select2({
            minimumResultsForSearch: -1,
            placeholder: {
                id: '-1', // the value of the option
                text: selectEl.dataset.placeholder
            },
        })
    });    
    
})