$(function() {
    
    $('.js-slider2').slick({
        slidesToShow: 2,
        dots: false,
        arrows: true,
        prevArrow: '.js-slider2-prev',
        nextArrow: '.js-slider2-next',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    arrows: false,
                }
            },
        ]
    });
    
    $('.js-slider3').slick({
        slidesToShow: 3,
        dots: false,
        arrows: true,
        prevArrow: '.js-slider3-prev',
        nextArrow: '.js-slider3-next',
        responsive: [
            {
                breakpoint: 1101,
                settings: {
                    slidesToShow: 2,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    arrows: false,
                }
            },
        ]
    });

})