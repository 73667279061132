$(function() {    

  let mapInitiated = {};

  const init = (mapContainerId, centeredOnPin) => {
      if (mapInitiated[mapContainerId] || typeof mapData === "undefined") return;

      let centerCoords = mapData.center;
      let pins = mapData.pins;
      let zoom = mapData.zoom;

      /*if (window.matchMedia('(max-width: 767px)').matches) {
          centerCoords = [59.986484, 30.244344];
      }*/

      ymaps.ready(function () {
          let myMap = new ymaps.Map(mapContainerId, {
              center: centerCoords,
              zoom: zoom,
              controls: [],
          });

          if (pins) {
              for (let i = 0; i < pins.length; i++) {
                  let myPlacemark = new ymaps.Placemark(
                      pins[i],
                      {},
                      {
                        iconLayout: 'default#image',
                        iconImageHref: window.assetsFolder + '/img/content/map-pin.svg',
                        iconImageSize: [47, 60],
                        iconImageOffset: [-23, -60],
                      }
                  );
  
                  myMap.geoObjects.add(myPlacemark);
              }
          }
          
      });

      mapInitiated[mapContainerId] = true;
  };

  let yaMapsLoaded = false;
  let mapsToInitOnLoad = {};
  let mapScriptRequested = false;

  function checkMapLoaded(mapContainerId, centeredOnPin) {
      if (!yaMapsLoaded) {
          if (
              $('#' + mapContainerId).length &&
              
              $(window).height() + $(window).scrollTop() >
              $('#' + mapContainerId).offset().top
          ) {
              mapsToInitOnLoad[mapContainerId] = centeredOnPin;

              if (!mapScriptRequested) {
                  mapScriptRequested = true;
                  loadScript(
                      'https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;loadByRequire=1&amp;apikey=fbbf3d32-9771-4e77-a827-bf684d94a66f',
                      function () {
                          yaMapsLoaded = true;

                          // Как только API Яндекс.Карт загрузились, сразу формируем карту и помещаем в блок с идентификатором &#34;map-yandex&#34;
                          ymaps.load(function () {
                              for (var mapContainerId in mapsToInitOnLoad) {
                                  if (
                                      mapsToInitOnLoad.hasOwnProperty(
                                          mapContainerId
                                      )
                                  ) {
                                      init(
                                          mapContainerId,
                                          mapsToInitOnLoad[mapContainerId]
                                      );
                                  }
                              }
                          });
                      }
                  );
              }
          }
      } else {
          init(mapContainerId, centeredOnPin);
      }
  }

  function loadScript(url, callback) {
      var script = document.createElement('script');

      if (script.readyState) {
          // IE
          script.onreadystatechange = function () {
              if (
                  script.readyState == 'loaded' ||
                  script.readyState == 'complete'
              ) {
                  script.onreadystatechange = null;
                  callback();
              }
          };
      } else {
          // Другие браузеры
          script.onload = function () {
              callback();
          };
      }

      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
  }

  $(window).scroll(() => {
      checkMapLoaded('map');
  });
  checkMapLoaded('map');

})

    