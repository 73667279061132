$(function() {
	let htmlEl = document.querySelector('html');
	let headerEl = document.querySelector('.header');
	if (!headerEl) return;

	let burgerBtn = headerEl.querySelector('.js-burger-btn');
	
	burgerBtn.addEventListener('click', function () {
		headerEl.classList.toggle('header_open');
		htmlEl.classList.toggle('open-menu');
	});		

})