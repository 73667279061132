$(function() {
	const showMoreBtns = document.querySelectorAll(`.js-show-more-news`);

    if (showMoreBtns.length > 0) {

        showMoreBtns.forEach((btn) => {

            btn.addEventListener(`click`, (evt) => {
                evt.preventDefault();
        
                const target = evt.target;
                const insertContainer = target.closest(`.js-tab-content`).querySelector(`.js-news-list`);
        
                const url = target.dataset.ajaxUrl;
                const offset = target.dataset.offset;
                const type  = target.dataset.newsType;
                const display = target.dataset.display;
        
                target.dataset.offset = offset * 2;
        
                const data = {
                    'type': type,
                    'offset': offset,
                    'url': url,
                    'display': display
                };
        
                ajaxSend(data)
                .then((response) => {
                    showMoreNews(response, target, insertContainer);
                })
                .catch((err) => {
                    console.error(err);
                });
                        
        
            })
    
        })

    }
       
})

async function ajaxSend (data) {
    const url = data.url;
    const response = await fetch(url , {
        method: `POST`,
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(data)
    });
    if (!response.ok) {
        throw new Error(`Ошибка по адресу ${url}, статус ошибки ${response.status}`);
    }
    return await response.json();
};

function showMoreNews(data, requestDataElement, insertDataContainer) {
    if (data.result === `success`) {
        insertDataContainer.insertAdjacentHTML(`beforeend`, data.content);
        return true;
    }
    
    if (data.result === `nomore-docs`) {
        requestDataElement.textContent = `Вы посмотрели все новости`;
        return false;
    }

}