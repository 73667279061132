$(function() {	

    if (IMask) {
        const phones = document.querySelectorAll('[type="tel"]');
        phones.forEach(phone => {
            IMask(phone, {
                mask: '+{7} (000) 000 - 00 - 00'
            })
        })            

        const emails = document.querySelectorAll('[type="email"]');
        emails.forEach(mail => {
            IMask(mail, {
            mask: /^\S*@?\S*$/,
            });
        });
    } else {
        console.error('IMask: не подключен модуль');
    }

    // -----------------------------------------------------------

    function validateRequiredInput (input) {

        if (input.type === 'tel') {
            if (input.value.length == 22) {
                input.parentElement.classList.remove('error');
                return true;
            } else {
                input.parentElement.classList.add('error');
                return false;
            }
        }

        if (input.type === 'text') {
            if (input.value.length !== 0) {
                input.parentElement.classList.remove('error');
                return true;
            } else {
                input.parentElement.classList.add('error');
                return false;
            }
        }

        if (input.type === 'checkbox') {
            if (input.checked) {
                input.parentElement.classList.remove('error');
                return true;
            } else {
                input.parentElement.classList.add('error');
                return false;
            }
        }

        if (input.type === 'email') {
            if (/^\S+@\S+$/.test(input.value)) {
                input.parentElement.classList.remove('error');
                return true;
            } else {
                input.parentElement.classList.add('error');
                return false;
            }
        }
    }

    function validateRequiredSelect(select) {
        if (select.value) {
            select.parentElement.classList.remove('error');
            return true;
        } else {
            select.parentElement.classList.add('error');
            return false;
        }
    }

    function formSubmit(evt) {
        let form = this;
        let vilidateResult = true;

        let requiredInputs = form.querySelectorAll('input[required]');   
        requiredInputs.forEach(function (input) {
            let validateItem = validateRequiredInput(input);
            vilidateResult = vilidateResult && validateItem;
        });  
        
        let requiredSelects = form.querySelectorAll('select[required]');
        requiredSelects.forEach(function (select) {
            let validateItem = validateRequiredSelect(select);
            vilidateResult = vilidateResult && validateItem;
        });

        if (!vilidateResult && evt) {
            // невалидна
            evt.preventDefault();
        } else {
            evt.preventDefault();

            var formData = new FormData($(form)[0]);

            $.ajax({
                type: form.method,
                url: form.action,
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function(data, textStatus, jqXHR) {
                    //process data
                    Fancybox.close();
                    new Fancybox([
                        {
                            src: "#success",                         
                        },
                    ]);
                },
                error: function(data, textStatus, jqXHR) {
                    //process error msg
                    console.log(data);

                    Fancybox.close();
                    new Fancybox([
                        {
                            src: "#error",                         
                        },
                    ]);
                },
            });
        }

        return vilidateResult;
    }

    const forms = document.querySelectorAll('.js-form-validation');
        
    forms.forEach(function (form) {
        form.manualValidate = formSubmit; // добавление метода форме для ручного вызова валдиации
        form.addEventListener('submit', formSubmit);

        let requiredFieldEls = form.querySelectorAll('input[required]');    
        requiredFieldEls.forEach(function (requiredFieldEl) {
            requiredFieldEl.addEventListener('input', function () {
                validateRequiredInput(this);
            });
        });

        let requiredSelects = form.querySelectorAll('select[required]');
        requiredSelects.forEach(function (select) {
            select.addEventListener('change', function () {
                validateRequiredSelect(this);
            });
        });
    });
   
})