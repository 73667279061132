$(function() {
    
    $('.js-main-slider').slick({
        slidesToShow: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        fade: true,
        speed: 500,
        infinite: true,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        touchThreshold: 100,
        prevArrow: '.js-main-slider-prev',
        nextArrow: '.js-main-slider-next',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            },
        ]
    });

    const setActiveDot = function () {
        let index = $('.js-main-slider').slick('slickCurrentSlide');
        $('.js-main-slider-dots').children().removeClass('active');
        $('.js-main-slider-dots').children().eq(index).addClass('active');   
    }

    $('.js-main-slider').on('setPosition', function(event, slick){
        setActiveDot();
    });

    $('.js-main-slider-dots').children().each(function (index, dotEl) {
        $(dotEl).on('click', function () {
            $('.js-main-slider').slick('slickGoTo', index);
        });
    });

})