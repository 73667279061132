$(function() {

    let filterInStock = function () {

        const modelValue = $('.js-model-filter').val();
        const modificationValue = $('.js-modification-filter').val();
        const colorValue = $('.js-color-filter').val();

        $('.card').each(function (index, cardEl) {
            if  (
                    (modelValue === 'all' || modelValue === cardEl.dataset.model) &&
                    (modificationValue === 'all' || modificationValue === cardEl.dataset.modification) &&
                    (colorValue === 'all' || colorValue === cardEl.dataset.color) 
                ) 
                {
                    $(cardEl).show();
                } else {
                    $(cardEl).hide();
                }
        })
    };

    $('.js-model-filter, .js-modification-filter, .js-color-filter').on('select2:select', function (e) {
        filterInStock();
    });
    
})